import { Anchor } from 'grommet';
import React, { FC } from 'react';
import { IFile } from '../utils/componentInterfaces';
import Block from './block';
import Intro from './intro';
import { StyledList } from './list';

interface Item {
    title: string;
    fieldGroupName: string;
    file: IFile;
}

interface props {
    heading: string;
    intro: string;
    items: Item[];
}

const Downloads: FC<props> = ({ heading, intro, items }) => (
    <Block direction="column" className="light-1">
        <Intro heading={heading} intro={intro} />

        <StyledList>
            {items.length >= 1 &&
                items.map((item, index) => (
                    <li key={`download-${index}`}>
                        <Anchor href={`${item.file.mediaItemUrl}`} target="_blank">
                            {item.title}
                        </Anchor>
                    </li>
                ))}
        </StyledList>
    </Block>
);

export default Downloads;
//
